<template>
  <div class="config-bg-wrapper">
    <div v-if="params && facilities">
      <div class="box">
        <div class="columns mb-0">
          <div class="column pb-0">
            <h2 class="title is-5">
              General
            </h2>
          </div>
          <div class="column is-narrow buttons" v-if="canEdit">
            <b-button
              class="is-pulled-right has-text-weight-bold"
              :type="
                updatesObj['general'] ? 'is-success is-light' : 'is-warning'
              "
              :icon-left="updatesObj['general'] ? 'chevron-up' : 'pencil'"
              size="is-small"
              @click="updatesObj['general'] = !updatesObj['general']"
              >{{ updatesObj["general"] ? "OK" : "Edit" }}</b-button
            >
          </div>
        </div>
        <bg-config-params
          v-model="params"
          :recap="!updatesObj['general']"
        ></bg-config-params>
        <div
          class="bg-transit-slide"
          :class="{
            'bg-transit-slide-open':
              updatesObj['general'] || updatesObj['faci'],
            'box-divider-greylane': updatesObj['general'] || updatesObj['faci']
          }"
        ></div>
        <div class="box-divider"></div>
        <!-- STAGES -->
        <div class="columns mb-0">
          <div class="column">
            <h2 class="title is-5">
              Supply-Chain Stages
            </h2>
          </div>
          <div class="column is-narrow buttons" v-if="canEdit">
            <b-button
              class="has-text-weight-bold"
              v-if="updatesObj['faci']"
              @click="$refs.bgconfigfaci.addFaci"
              :disabled="Object.keys(facilities).length >= 8"
              type="is-success"
              size="is-small"
              icon-left="plus"
            >
              <b-tooltip
                position="is-left"
                type="is-dark"
                :label="
                  Object.keys(facilities).length >= 8
                    ? 'No more than 8 stages allowed'
                    : ''
                "
              >
                Add Stage
              </b-tooltip>
            </b-button>
            <b-button
              class="has-text-weight-bold"
              :type="updatesObj['faci'] ? 'is-success is-light' : 'is-warning'"
              :icon-left="updatesObj['faci'] ? 'chevron-up' : 'pencil'"
              size="is-small"
              @click="updatesObj['faci'] = !updatesObj['faci']"
              >{{ updatesObj["faci"] ? "OK" : "Edit" }}</b-button
            >
          </div>
        </div>
        <bg-config-faci
          ref="bgconfigfaci"
          :isLive="false"
          :params="params"
          v-model="facilities"
          :recap="!updatesObj['faci']"
        ></bg-config-faci>
        <!-- FINAL CONSUMER DEMAND -->
        <div
          class="bg-transit-slide"
          :class="{
            'bg-transit-slide-open': updatesObj['demand'] || updatesObj['faci'],
            'box-divider-greylane': updatesObj['demand'] || updatesObj['faci']
          }"
        ></div>
        <div class="box-divider"></div>
        <div class="columns mb-0">
          <div class="column">
            <h2 class="title is-5">
              Final Consumer Demand
            </h2>
          </div>
          <div class="column is-narrow buttons">
            <b-button
              v-if="canEdit"
              class="has-text-weight-bold"
              :type="
                updatesObj['demand'] ? 'is-success is-light' : 'is-warning'
              "
              :icon-left="updatesObj['demand'] ? 'chevron-up' : 'pencil'"
              size="is-small"
              @click="updatesObj['demand'] = !updatesObj['demand']"
              >{{ updatesObj["demand"] ? "OK" : "Edit" }}</b-button
            >
          </div>
        </div>
        <bg-config-demand
          v-model="params.consumerDemand"
          :total-weeks="params.totalWeeks"
          ref="myConfigDemand"
          :my-style="{ 'max-height': updatesObj['demand'] ? '260px' : '100px' }"
          :retailer-name="facilities['1'].name"
          :disable="false"
          :recap="!updatesObj['demand']"
        ></bg-config-demand>
        <!-- AUTO MESSAGES -->
        <div
          class="bg-transit-slide"
          :class="{
            'bg-transit-slide-open':
              updatesObj['demand'] || updatesObj['messages'],
            'box-divider-greylane':
              updatesObj['demand'] || updatesObj['messages']
          }"
        ></div>
        <div class="box-divider"></div>
        <div class="columns mb-0">
          <div class="column">
            <h2 class="title is-5 pb-0">
              Messages
            </h2>
          </div>
          <div class="column is-narrow buttons" v-if="canEdit">
            <b-button
              class="has-text-weight-bold"
              :type="
                updatesObj['messages'] ? 'is-success is-light' : 'is-warning'
              "
              :icon-left="updatesObj['messages'] ? 'chevron-up' : 'pencil'"
              size="is-small"
              @click="updatesObj['messages'] = !updatesObj['messages']"
              >{{ updatesObj["messages"] ? "OK" : "Edit" }}</b-button
            >
          </div>
        </div>
        <bg-config-messages
          v-model="params.configMessages"
          :facilities="facilities"
          :disable="false"
          :time-unit="params.timeUnit"
          :recap="!updatesObj['messages']"
        ></bg-config-messages>
      </div>
    </div>
  </div>
</template>

<script>
import BgConfigParams from "@/components/bg/Create/BgConfigParams.vue";
import BgConfigFaci from "@/components/bg/Create/BgConfigFaci.vue";
import BgConfigDemand from "@/components/bg/Create/BgConfigDemand.vue";
import BgConfigMessages from "@/components/bg/Create/BgConfigMessages.vue";
import { required, between } from "vuelidate/lib/validators";

export default {
  name: "BgConfig",
  components: {
    BgConfigParams,
    BgConfigFaci,
    BgConfigDemand,
    BgConfigMessages
  },
  props: {
    value: Object,
    canEdit: Boolean
  },
  created: function() {
    if (this.value.params) {
      this.params = { ...this.value.params };
    }
    if (this.value.facilities) {
      this.facilities = { ...this.value.facilities };
    }
  },
  computed: {
    arrten() {
      let tab = [];
      for (let ii = 0; ii <= 100; ii++) {
        if (ii % 10 === 0) {
          tab.push(ii);
        }
      }
      return tab;
    },
    weeksArray() {
      let arr = [];
      for (let ii = 1; ii <= 100; ii++) {
        if (ii / 10 - Math.floor(ii / 10) === 0) {
          arr.push(ii);
        }
      }
      return arr;
    },
    facis() {
      return Object.values(this.facilities).sort((a, b) => {
        return parseInt(a.id) - parseInt(b.id);
      });
    },
    sumFaciData() {
      let obj = {};
      this.facis.forEach(faci => {
        Object.keys(faci).forEach(kk => {
          if (faci.hasOwnProperty(kk)) {
            if (obj[kk]) {
              obj[kk] = obj[kk] + faci[kk];
            } else {
              obj[kk] = faci[kk];
            }
          }
        });
      });
      return obj;
    }
  },
  watch: {
    params: {
      handler() {
        return this.emitUpdate();
      },
      deep: true
    },
    facilities: {
      handler() {
        return this.emitUpdate();
      },
      deep: true
    },
    "updatesObj.demand"(newUp) {
      if (newUp) {
        setTimeout(() => {
          if (this.$refs.myConfigDemand.$refs.myDemandChart) {
            this.$refs.myConfigDemand.$refs.myDemandChart.$refs.chart.launchUpdate();
          }
        }, 160);
      }
    }
  },
  methods: {
    emitUpdate() {
      this.$emit("input", {
        ...this.value,
        params: this.params,
        facilities: this.facilities
      });
    }
  },
  validations() {
    let retObj = {
      facilities: {},
      params: {
        product: { required },
        totalWeeks: {
          required,
          between: between(2, 200)
        }
      }
    };
    if (this.facilities) {
      Object.keys(this.facilities).forEach(id => {
        retObj.facilities[id] = {
          name: { required },
          stock: { required, between: between(0, 9999) },
          backlog: { required, between: between(0, 9999) },
          leadtime: { required, between: between(2, 6) },
          stockCost: {
            required,
            between: between(0, 999)
          },
          backlogCost: {
            required,
            between: between(0, 999)
          },
          startOrders: {
            required,
            between: between(0, 999)
          },
          moq: {
            required,
            between: between(0, 999)
          },
          batchSize: {
            required,
            between: between(0, 999)
          },
          visibleWeeks: {
            required,
            between: between(0, 6)
          }
        };
      });
    }

    return retObj;
  },
  data() {
    return {
      updatesObj: {
        general: false,
        basic: false,
        premium: false,
        demand: false,
        faci: false,
        messages: false
      },
      params: null,
      facilities: null
    };
  }
};
</script>

<style lang="scss">
.config-bg-wrapper {
  padding: 0;
  .box {
    margin-bottom: 0.75rem;
  }
  .mr3 {
    margin-right: 6em !important;
  }
  .mtb5 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .nopadtop {
    padding-top: 0;
  }
  .link-templates {
    text-decoration: underline;
  }
  .field-label.is-normal {
    padding-top: 0;
  }
  .form-group {
    margin-top: 1em;
    margin-bottom: 0;
    padding-top: 0.2em;
    border-top: $primary 1px dashed;
    .column {
      padding: 0 1em;
    }
  }
  .columns {
    overflow: hidden;
    &.disabled {
      .label {
        opacity: 0.6;
      }
    }
  }
  .modal-card-body {
    .b-radio {
      margin-right: 1em;
    }
  }
  .modal-card-foot {
    // display: flex;
    justify-content: flex-end;
    .button {
      margin: 0 1em;
    }
  }
}
</style>
