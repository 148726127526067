var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"config-bg-wrapper"},[(_vm.params && _vm.facilities)?_c('div',[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns mb-0"},[_vm._m(0),(_vm.canEdit)?_c('div',{staticClass:"column is-narrow buttons"},[_c('b-button',{staticClass:"is-pulled-right has-text-weight-bold",attrs:{"type":_vm.updatesObj['general'] ? 'is-success is-light' : 'is-warning',"icon-left":_vm.updatesObj['general'] ? 'chevron-up' : 'pencil',"size":"is-small"},on:{"click":function($event){_vm.updatesObj['general'] = !_vm.updatesObj['general']}}},[_vm._v(_vm._s(_vm.updatesObj["general"] ? "OK" : "Edit"))])],1):_vm._e()]),_c('bg-config-params',{attrs:{"recap":!_vm.updatesObj['general']},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}}),_c('div',{staticClass:"bg-transit-slide",class:{
          'bg-transit-slide-open':
            _vm.updatesObj['general'] || _vm.updatesObj['faci'],
          'box-divider-greylane': _vm.updatesObj['general'] || _vm.updatesObj['faci']
        }}),_c('div',{staticClass:"box-divider"}),_c('div',{staticClass:"columns mb-0"},[_vm._m(1),(_vm.canEdit)?_c('div',{staticClass:"column is-narrow buttons"},[(_vm.updatesObj['faci'])?_c('b-button',{staticClass:"has-text-weight-bold",attrs:{"disabled":Object.keys(_vm.facilities).length >= 8,"type":"is-success","size":"is-small","icon-left":"plus"},on:{"click":_vm.$refs.bgconfigfaci.addFaci}},[_c('b-tooltip',{attrs:{"position":"is-left","type":"is-dark","label":Object.keys(_vm.facilities).length >= 8
                  ? 'No more than 8 stages allowed'
                  : ''}},[_vm._v(" Add Stage ")])],1):_vm._e(),_c('b-button',{staticClass:"has-text-weight-bold",attrs:{"type":_vm.updatesObj['faci'] ? 'is-success is-light' : 'is-warning',"icon-left":_vm.updatesObj['faci'] ? 'chevron-up' : 'pencil',"size":"is-small"},on:{"click":function($event){_vm.updatesObj['faci'] = !_vm.updatesObj['faci']}}},[_vm._v(_vm._s(_vm.updatesObj["faci"] ? "OK" : "Edit"))])],1):_vm._e()]),_c('bg-config-faci',{ref:"bgconfigfaci",attrs:{"isLive":false,"params":_vm.params,"recap":!_vm.updatesObj['faci']},model:{value:(_vm.facilities),callback:function ($$v) {_vm.facilities=$$v},expression:"facilities"}}),_c('div',{staticClass:"bg-transit-slide",class:{
          'bg-transit-slide-open': _vm.updatesObj['demand'] || _vm.updatesObj['faci'],
          'box-divider-greylane': _vm.updatesObj['demand'] || _vm.updatesObj['faci']
        }}),_c('div',{staticClass:"box-divider"}),_c('div',{staticClass:"columns mb-0"},[_vm._m(2),_c('div',{staticClass:"column is-narrow buttons"},[(_vm.canEdit)?_c('b-button',{staticClass:"has-text-weight-bold",attrs:{"type":_vm.updatesObj['demand'] ? 'is-success is-light' : 'is-warning',"icon-left":_vm.updatesObj['demand'] ? 'chevron-up' : 'pencil',"size":"is-small"},on:{"click":function($event){_vm.updatesObj['demand'] = !_vm.updatesObj['demand']}}},[_vm._v(_vm._s(_vm.updatesObj["demand"] ? "OK" : "Edit"))]):_vm._e()],1)]),_c('bg-config-demand',{ref:"myConfigDemand",attrs:{"total-weeks":_vm.params.totalWeeks,"my-style":{ 'max-height': _vm.updatesObj['demand'] ? '260px' : '100px' },"retailer-name":_vm.facilities['1'].name,"disable":false,"recap":!_vm.updatesObj['demand']},model:{value:(_vm.params.consumerDemand),callback:function ($$v) {_vm.$set(_vm.params, "consumerDemand", $$v)},expression:"params.consumerDemand"}}),_c('div',{staticClass:"bg-transit-slide",class:{
          'bg-transit-slide-open':
            _vm.updatesObj['demand'] || _vm.updatesObj['messages'],
          'box-divider-greylane':
            _vm.updatesObj['demand'] || _vm.updatesObj['messages']
        }}),_c('div',{staticClass:"box-divider"}),_c('div',{staticClass:"columns mb-0"},[_vm._m(3),(_vm.canEdit)?_c('div',{staticClass:"column is-narrow buttons"},[_c('b-button',{staticClass:"has-text-weight-bold",attrs:{"type":_vm.updatesObj['messages'] ? 'is-success is-light' : 'is-warning',"icon-left":_vm.updatesObj['messages'] ? 'chevron-up' : 'pencil',"size":"is-small"},on:{"click":function($event){_vm.updatesObj['messages'] = !_vm.updatesObj['messages']}}},[_vm._v(_vm._s(_vm.updatesObj["messages"] ? "OK" : "Edit"))])],1):_vm._e()]),_c('bg-config-messages',{attrs:{"facilities":_vm.facilities,"disable":false,"time-unit":_vm.params.timeUnit,"recap":!_vm.updatesObj['messages']},model:{value:(_vm.params.configMessages),callback:function ($$v) {_vm.$set(_vm.params, "configMessages", $$v)},expression:"params.configMessages"}})],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column pb-0"},[_c('h2',{staticClass:"title is-5"},[_vm._v(" General ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('h2',{staticClass:"title is-5"},[_vm._v(" Supply-Chain Stages ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('h2',{staticClass:"title is-5"},[_vm._v(" Final Consumer Demand ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column"},[_c('h2',{staticClass:"title is-5 pb-0"},[_vm._v(" Messages ")])])}]

export { render, staticRenderFns }